import http from '@/util/http'
const inventoryApi = {
    //筛选库存
    getStocks(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/getStocks'), data)
    },
    //获取所有快捷标签
    stockFilterFlags(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/stockFilterFlags'), data)
    },
    //新增快捷过滤标签
    addStockFilterFlag(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/addStockFilterFlag'), data)
    },
    //删除快捷过滤标签
    delStockFilterFlag(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/delStockFilterFlag'), data)
    },
    //原材料入库
    addStockByMaterials(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/addStockByMaterials'), data)
    },
    //产品入库
    addStockByGoods(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/addStockByGoods'), data)
    },
    //获取所有的损耗类型
    wastageTypes() {
        return http.post(http.adornUrl('/hy-admin/backend/stock/wastageTypes'))
    },
    //新增损耗类型
    addWastageType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/addWastageType'), data)
    },
    //编辑损耗类型
    editWastageType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/editWastageType'), data)
    },
    //删除损耗类型
    delWastageType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/delWastageType'), data)
    },
    //根据材料记录损耗
    addWastageStockByMaterials(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/addWastageStockByMaterials'), data)
    },
    //根据产品记录损耗
    addWastageStockByGoods(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/addWastageStockByGoods'), data)
    },
    //获取损耗列表
    getLossList(data) {
        return http.post(http.adornUrl('/hy-admin/backend/stock/getStockWastage'), data)
    }
}
export default inventoryApi