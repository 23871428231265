export default {
    pushToken: (state, src) => {
        state.cancelTokenArr.push(src.cancelToken)
    },
    clearToken: ({ cancelTokenArr }) => {
        cancelTokenArr.forEach(item => {
            item('路由跳转取消请求')
        })
        cancelTokenArr = []
    },
    setRole: (state, role) => {
        state.curRole = role
    }
}