import loginApi from './modules/login'
import matedataApi from './modules/matedata'
import productApi from './modules/product'
import flowApi from './modules/flow'
import agentApi from './modules/agent'
import expressApi from './modules/express'
import supplierApi from './modules/supplier'
import inventoryApi from './modules/inventory'
import sysUserApi from './modules/sysUser'
import financeApi from './modules/finance'
export default {
    loginApi,
    matedataApi,
    productApi,
    flowApi,
    agentApi,
    expressApi,
    supplierApi,
    inventoryApi,
    sysUserApi,
    financeApi
}
