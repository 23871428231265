<template>
  <el-pagination
    background
    :total="total"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    :layout="layout"
    @size-change="sizeChange"
    @current-change="currentChange"
  >
  </el-pagination>
</template>
<script>
export default {
  name: "Pagination",
  props: {
    pageSize: Number,
    page: Number,
    total: Number,
    layout: {
      type: String,
      default: "total,prev, pager, next,sizes",
    },
  },
  data() {
    return {
      pageSizes: [10, 20, 30, 40, 50],
    };
  },
  methods: {
    //改变页面展示数量
    sizeChange(pageSize) {
      this.$emit("sizeChange", pageSize);
    },
    //切换页数
    currentChange(page) {
      this.$emit("pageChange", page);
    },
  },
};
</script>
<style lang="less" scoped>
</style>