import api from '@/api/api'
export default {
    //获取模型列表
    async getGoodsModels(ctx, data = false) {
        if (ctx.rootState.modelList.lenght > 0 && data === false) {
            return
        }
        const res = await api.productApi.getGoodsModels(data)
        const modelList = res.goodsmodels
        ctx.commit('modelData', modelList)
        return modelList
    },
    //新增产品
    async addProd(ctx,) {
        let data = await api.matedataApi.getMaterials()
    },
    //获取模型属性
    async getModelAttr(ctx, data) {
        return await api.productApi.getGoodsModelAttributes(data)
    },
    //新增产品
    async createGoods(ctx, data) {
        return await api.productApi.createGoods(data)
    },
    //删除产品
    async delGoods(ctx, data) {
        return await api.productApi.delGoods(data)
    },
    //获取所属模型下的所有产品
    async getModelFindProd(ctx, data) {
        const res = await api.productApi.getGoodsByGoodsModelId(data)
        return res.goods
    },
    //根据产品id获取规格列表
    async getProdFindSpecList(ctx, data) {
        const res = await api.productApi.getGoodsSpecs(data)
        const specList = res.specs
        return specList
    },
    //获取规格属性列表
    async getSpeAttrcList(ctx, data) {
        const res = await api.productApi.getSpecAttrList(data)
        return res
    },
    //获取规格检阅表
    async getSpecStatistics(ctx, data) {
        return await api.productApi.getSpecStatistics(data)
    },
    //获取对应模型支持的定制面
    async getModelFindPlane(ctx, data) {
        const res = await api.productApi.getGoodsModelSupportDesignSides(data)
        return res.sides
    },
    //上传手机壳装饰图片
    async uploadAdornImage(ctx, formData) {
        return await api.productApi.uploadDesignSideAttributeImage(formData)

    },
    //新增或修改规格
    async createOrEditGoodsSpec(ctx, data) {
        return await api.productApi.createOrEditGoodsSpec(data)
    },
    //删除规格
    async delSpec(ctx, data) {
        return await api.productApi.delSpec(data)
    },
    //获取规格信息
    async getGoodsSpecSumInfo(ctx, data) {
        const res = await api.productApi.getGoodsSpecSumInfo(data)
        return res.spec
    },
    //设置规格缺货
    async postSpecStockout(ctx, data) {
        return await api.productApi.postSpecStockout(data)
    },
    //重打图片
    async againOrder(ctx, data) {
        return await api.productApi.againOrder(data)

    },
    //获取型号表
    async getModelList() {
        return await api.productApi.getModelList()
    },
    //获取售后订单列表
    async getRefundOrderItems(ctx, data) {
        return await api.productApi.getRefundOrderItems(data)
    }
}