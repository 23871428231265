
import http from '@/util/http'

const agentApi = {
    //获取某种代理或全部代理
    getAgents(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/getAgents'), data)
    },
    //获取代理类型
    getAgentTypes() {
        return http.post(http.adornUrl('/hy-admin/backend/agent/getAgentTypes'))
    },
    //获取代理级别
    getAgentLevels() {
        return http.post(http.adornUrl('/hy-admin/backend/agent/getAgentLevels'))
    },
    //新增代理
    createAgent(data) {
        return http.post(http.adornUrl('/hy-admin/agent/createAgent'), data)
    },
    //编辑代理
    editAgent(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/editAgent'), data)
    },
    //获取代理商品价格
    getAgentGoodsesPrice(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/getAgentGoodsesPrice'), data)
    },
    //设置代理商品价格
    setAgentGoodsPrice(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/setAgentGoodsPrice'), data)
    },
    agentRecharge(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/agentRecharge'), data)
    },
    //获取代理的快递价格
    getAgentExpressesPrice(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/getAgentExpressesPrice'), data)
    },
    //设置代理的快递价格
    setAgentExpressPrice(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/setAgentExpressPrice'), data)
    },
    //新增代理级别
    createAgentLevel(data) {
        return http.post(http.adornUrl('/hy-admin/agent/createAgentLevel'), data)
    },
    //编辑代理级别
    editAgentLevel(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/editAgentLevel'), data)
    },
    //新增代理类型
    createAgentType(data) {
        return http.post(http.adornUrl('/hy-admin/agent/createAgentType'), data)
    },
    //编辑代理类型
    editAgentType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/editAgentType'), data)
    },
    //删除代理类型
    delAgentType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/delAgentType'), data)
    },
    //删除代理等级
    delAgentLevel(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/delAgentLevel'), data)
    },
    //删除代理
    delAgent(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/delAgent'), data)
    },
    //代理统计
    agentStatistic(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/agentStatistic'), data)
    },
    //获取公告列表
    getNoticeList(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/getAgentAnnounceList'), data)
    },
    //新增公告
    postAddNotice(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/createAgentAnnounce'), data)
    },
    //获取公告详情
    getNoticeDetail(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/getAgentAnnounce'), data)
    }

}
export default agentApi
