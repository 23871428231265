import axios from 'axios'
import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import {
    Loading
} from 'element-ui'

/**
 * 获取uuid
 */
export function getUUID() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
        return (c === 'x' ?
            (Math.random() * 16) | 0 :
            'r&0x3' | '0x8'
        ).toString(16)
    })
}
//设置局部loading
let loading

function startLoading() {
    //使用Element loading-start 方法
    loading = Loading.service({
        lock: false,
        text: '拼命加载中...',
        background: 'rgba(255,255,255,0)',
        target: document.querySelector('.loading-area'),
    })
}

function endLoading() {
    //使用Element loading-close 方法
    loading.close()
}
let needLoadingRequestCount = 0

function showFullScreenLoading() {
    needLoadingRequestCount++

    if (needLoadingRequestCount == 1) {
        startLoading()
    }
}

function hideFullScreenLoading() {
    needLoadingRequestCount--
    if (needLoadingRequestCount <= 0) {
        endLoading()
    }
}
const root =
    process.env.NODE_ENV == 'production' ?
        'http://newbackend.kylpdz.com' :
        '/api'
let vueobj = new Vue()
const toSignin = function (msg) {
    Vue.$cookies.remove('token')
    Vue.$cookies.remove('username')
    var message = msg ? msg : '登录过期，即将前往登录页面'
    vueobj.$message.error({
        showClose: true,
        message: message,
        duration: 2000,
        onClose: function () {
            router.replace({
                name: 'login'
            })
        },
    })
}
//请求失败后的统一处理
const errorHandle = (status, other) => {
    // 状态码判断
    switch (status) {
        case 400:
            vueobj.$message.error({
                showClose: true,
                message: '请求参数有误！',
                duration: 2000,
            })
            break
        // 401: 未登录状态，跳转登录页
        case 401:
            //vueobj.$message.error({showClose: true, message: "session过期，即将前往登录页面！", duration: 3000});
            toSignin()
            break
        // 403 token过期
        // 清除token并跳转登录页
        case 403:
            vueobj.$message.error({
                showClose: true,
                message: '没有操作权限！',
                duration: 2000,
            })
            break
        // 404请求不存在
        case 404:
            vueobj.$message.error({
                showClose: true,
                message: '请求不存在',
                duration: 2000,
            })
            break
        case 500:
            vueobj.$message.error({
                showClose: true,
                message: '请求失败，服务器内部错误',
                duration: 2000,
            })
            break
        case 504:
            vueobj.$message.error({
                showClose: true,
                message: '与服务器连接失败！',
                duration: 2000,
            })
            break
        default:
            vueobj.$message.error({
                message: '服务器连接失败,请联系大神救场',
                duration: 2000,
            })
    }
}
//创建请求实例
const http = axios.create({
    timeout: 1000 * 10,
    // withCredentials: true,
    headers: {
        'Content-Type': 'application/json;charset=utf-8', //'application/x-www-form-urlencoded;charset=utf-8'
    },
})
http.defaults.retry = 1 //请求次数
http.defaults.retryDelay = 1000 //请求间隙
http.adornUrl = (actionName) => {
    return root + actionName
}
http.adornData = (data = {}) => {
    var defaults = {
        t: new Date().getTime(),
    }
    data = data.push({
        date: defaults
    })
    return data
}
//设置请求的配置项config
//设置超时时间
//http.defaults.baseURL='http://112.74.41.235:9501/'
//http.defaults.timeout='10000'
//跨域请求,允许保存cookie
//http.defaults.widthCredebtials=true
//设置posy请求传递的格式
//http.defaults.headers['content-type']='application/x-www-from-urlencoded'
//自动转换需要的格式
// http.defaults.transfromrequest=(data)=>{
//     qs.stringify(data)
// }
//请求拦截
http.interceptors.request.use(
    (config) => {
        if (!navigator.onLine) {
            return config
        }
        showFullScreenLoading()
        if (Vue.$cookies.get('token')) {
            config.headers['token'] = Vue.$cookies.get('token') // 请求头带上token
        } else {
            if (router.history.current.name != 'login') {
                toSignin()
            }
        }
        config.cancelToken = new axios.CancelToken((cancel) => {
            store.commit('pushToken', {
                cancelToken: cancel,
            })
        })
        return config
    },
    (error) => {
        hideFullScreenLoading()
        needLoadingRequestCount = 0
        return Promise.reject(error)
    }
)

//如果请求成功,验证状态码,如果是200进入请求拦截,如果不是,进入请求error回调
http.defaults.validateStatus = (status) => {
    //会先进入到这里做出判断
    return status == 200
    //有时候我们状态码是正确的,但是业务没有满足我们的需要也是要在这里判断处理的
}
//响应拦截
http.interceptors.response.use(
    (response) => {
        hideFullScreenLoading()
        //200请求成功
        if (response.data.code == '401') {
            Vue.$cookies.remove('token')
            vueobj.$message({
                message: '登录过期!',
                type: 'warning',
            })
            router.replace('/login')
        }
        return response.data
    },
    (error) => {
        console.dir(error)
        hideFullScreenLoading()
        if (error.message === '路由跳转取消请求') {
            // 判断是否为路由跳转取消网络请求
            console.log('路由跳转取消请求' + error)
        }

        if (error.response && error) {
            //服务器返回结果了,只不过不是200
            errorHandle(error.response.status, '响应了但不是200')
        }
        if (error.message === 'Network Error') {
            vueobj.$message.error({
                showClose: true,
                message: '去看看是不是断网了呀',
                duration: 3000,
            })
        }
        if (error.message === 'timeout of 10000ms exceeded') {
            errorHandle(600, '响应了但不是200')

        }
        return Promise.reject(error)
    }
)
export default http