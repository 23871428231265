import http from '@/util/http'
const matedataApi = {
    //获取所有品牌
    allBrands(data) {
        return http.post(http.adornUrl('/hy-admin/backend/allBrands'), data)
    },
    //获取所有型号
    allTypes(data) {
        return http.post(http.adornUrl('/hy-admin/backend/allTypes'), data)
    },
    allStuffCategories() {
        return http.post(http.adornUrl('/hy-admin/backend/getStuffCategories'))
    },
    //获取所有材质
    allStuffs(data) {
        return http.post(http.adornUrl('/hy-admin/backend/allStuffs'), data)
    },
    //获取品牌型号树
    brandTypeTree() {
        return http.post(http.adornUrl('/hy-admin/backend/brandTypeTree'))
    },
    //新增品牌
    newBrand(data) {
        return http.post(http.adornUrl('/hy-admin/backend/newBrand'), data)
    },
    //删除品牌
    delPhoneBrand(data) {
        return http.post(http.adornUrl('/hy-admin/backend/delPhoneBrand'), data)
    },
    //新增型号
    newType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/newType'), data)
    },
    //新增材质
    newPhoneStuff(data) {
        return http.post(http.adornUrl('/hy-admin/backend/newPhoneStuff'), data)
    },
    //编辑品牌
    editBrand(data) {
        return http.post(http.adornUrl('/hy-admin/backend/editBrand'), data)
    },
    //编辑型号
    editType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/editType'), data)
    },
    //删除型号
    delPhoneType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/delPhoneType'), data)
    },
    //新增材质分类
    newStuffCategory(data) {
        return http.post(http.adornUrl('/hy-admin/backend/newPhoneStuffCategory'), data)
    },
    //删除材质分类
    delPhoneStuffCategory(data) {
        return http.post(http.adornUrl('/hy-admin/backend/delPhoneStuffCategory'), data)
    },
    //编辑材质
    editPhoneStuff(data) {
        return http.post(http.adornUrl('/hy-admin/backend/editPhoneStuff'), data)
    },
    //删除材质
    delPhoneStuff(data) {
        return http.post(http.adornUrl('/hy-admin/backend/delPhoneStuff'), data)
    },
    //获取品牌下面的所有型号
    brandTypes(data) {
        return http.post(http.adornUrl('/hy-admin/backend/brandTypes'), data)
    },
    //获取原材料
    getMaterials(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getMaterials'), data)
    },
    //新增原材料
    newMaterial(data) {
        return http.post(http.adornUrl('/hy-admin/backend/newMaterial'), data)
    },
    //获取原材料类别
    getMaterialCateories() {
        return http.post(http.adornUrl('/hy-admin/backend/getMaterialCateories'))
    },
    //新增原材料分类
    newMaterialCategory(data) {
        return http.post(http.adornUrl('/hy-admin/backend/newMaterialCategory'), data)
    },
    //编辑原材料
    editMaterial(data) {
        return http.post(http.adornUrl('/hy-admin/backend/editMaterial'), data)
    },
    //删除原材料
    delMaterial(data) {
        return http.post(http.adornUrl('hy-admin/backend/delMaterial'), data)
    }

}
export default matedataApi
