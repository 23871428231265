<template>
  <el-table
    :data="list"
    highlight-current-row
    :border="true"
    style="margin-top: 20px"
    :max-height="height"
    :tree-props="treeProps"
    :row-key="rowKey"
    :show-summary="summary"
    @selection-change="handleSelectionChange"
  >
    <slot></slot>
  </el-table>
</template>
<script>
export default {
  name: "TableContainer",
  props: {
    list: Array,
    height: {
      type: String,
      default: "750",
    },
    treeProps: Object,
    rowKey: String,
    summary: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    handleSelectionChange(e) {
      this.$emit("selection-change", e);
    },
  },
};
</script>
<style lang="less" scoped>
</style>