import http from '@/util/http'
const expressApi = {
    //获取所有快递商
    getAllExpress(data) {
        return http.post(http.adornUrl('/hy-admin/backend/express/getAllExpress'), data)
    },
    //新增快递商
    newExpress(data) {
        return http.post(http.adornUrl('/hy-admin/backend/express/newExpress'), data)
    },
    //编辑快递商
    editExpress(data) {
        return http.post(http.adornUrl('/hy-admin/backend/express/editExpress'), data)
    },
    //删除快递商
    delExpress(data) {
        return http.post(http.adornUrl('/hy-admin/backend/express/delExpress'), data)
    }
}
export default expressApi