export default [
    {
        path: "/PhoneBrand",
        component: () => import("@/views/home/components/mate-data/brand-type/PhoneBrand"),
        name: "PhoneBrand",
        meta: { title: "品牌" }
    },
    {
        path: "/PhoneType",
        component: () => import("@/views/home/components/mate-data/brand-type/PhoneType"),
        name: "PhoneType",
        meta: { title: "型号" }
    },
    {
        path: "/StuffType",
        component: () => import("@/views/home/components/mate-data/stuff/StuffType"),
        name: "StuffType",
        meta: { title: "材质分类" }
    },
    {
        path: "/StuffList",
        component: () => import("@/views/home/components/mate-data/stuff/StuffList"),
        name: "StuffList",
        meta: { title: "材质列表" }
    },
    {
        path: "/MaterialType",
        component: () => import("@/views/home/components/mate-data/material/MaterialType"),
        name: "MaterialType",
        meta: { title: "原材料类型" }
    },
    {
        path: "/MaterialList",
        component: () => import("@/views/home/components/mate-data/material/MaterialList"),
        name: "MaterialList",
        meta: { title: "原材料列表" }
    }
]
