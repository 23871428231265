export default {
    path: "/express",
    component: () => import("@/views/home/components/express/index.vue"),
    name: "express",
    children: [
        {
            path: "/expressmanage",
            component: () => import("@/views/home/components/express/expressmanage/index.vue"),
            name: "expressmanage",
            meta: { title: "快递管理" }
        }
    ]
}