import http from '@/util/http'
const supplierApi = {
    //获取所有供应商
    getAllSuppliers() {
        return http.post(http.adornUrl('/hy-admin/backend/getAllSuppliers'))
    },
    //新增供应商
    newSupplier(data) {
        return http.post(http.adornUrl('/hy-admin/backend/supplier/newSupplier'), data)
    },
    //编辑供应商
    editSupplier(data) {
        return http.post(http.adornUrl('/hy-admin/backend/supplier/editSupplier'), data)
    },
    //删除供应商
    delSupplier(data) {
        return http.post(http.adornUrl('/hy-admin/backend/supplier/delSupplier'), data)
    }
}
export default supplierApi