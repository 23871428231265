import api from '@/api/api'
export default {
    //代理充值
    async agentRecharge(ctx, data) {
        return await api.financeApi.agentRecharge(data)
    },
    //订单统计
    async getInOutOrderStatistic(ctx, data) {
        return await api.financeApi.getInOutOrderStatistic(data)
    },
    //获取整体充值和资金使用情况
    async getTotalRechargesAndUsage(ctx, data) {
        return await api.financeApi.getTotalRechargesAndUsage(data)
    },
    //获取每个代理类型下代理的充值和使用情况
    async getCategoryAgentsStatistic(ctx, data) {
        return await api.financeApi.getCategoryAgentsStatistic(data)
    },
    //获取代理的充值和使用情况
    async getAgentBalanceLog(ctx, data) {
        return await api.financeApi.getAgentBalanceLog(data)
    },
    //获取代理订单信息
    async getAgentOrders(ctx, data) {
        return await api.financeApi.getAgentOrders(data)
    }
}