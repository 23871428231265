<template>
  <el-button type="danger" @click="handleDelete">删除</el-button>
</template>
<script>
export default {
  name: "DeleteBtn",
  props: {
    item: Object,
  },
  methods: {
    handleDelete() {
      this.$confirm("确认删除？")
        .then(() => {
          this.$emit("del", this.item);
        })
        .catch(() => {});
    },
  },
};
</script>
<style lang="less" scoped>
</style>