import http from '@/util/http'
const productApi = {
    //获取所有模型
    getGoodsModels(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getGoodsModels'), data)
    },
    //获取模型构建的产品列表
    getGoodsByGoodsModelId(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getGoodsByGoodsModelId'), data)
    },
    //获取模型所有属性
    getGoodsModelAttributes(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getGoodsModelAttributes'), data)
    },
    //新增产品
    createGoods(data) {
        return http.post(http.adornUrl('/hy-admin/backend/createGoods'), data)
    },
    //新增材质
    newPhoneStuff(data) {
        return http.post(http.adornUrl('/hy-admin/backend/newPhoneStuff'), data)
    },
    //获取所有产品列表
    getAllGoods(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getAllGoods'), data)
    },
    //删除产品
    delGoods(data) {
        return http.post(http.adornUrl('/hy-admin/backend/deleteGoods'), data)
    },
    //获取某一产品规格
    getGoodsSpecs(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getGoodsSpecs'), data)
    },
    // //获取某一产品规格必要属性
    // getGoodsModelAttributes(data){
    //     return http.post(http.adornUrl('/hy-admin/backend/getGoodsModelAttributes'),data)
    // },
    //获取某一产品规格必要属性的可选值
    getGoodsAttrOptions(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getGoodsAttrOptions'), data)
    },
    //为某一产品新增规格
    createGoodsSpec(data) {
        return http.post(http.adornUrl('/hy-admin/backend/createGoodsSpec'), data)
    },
    //获取规格供应商列表
    getSpecSuppliers(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getSpecSuppliers'), data)
    },
    //设置供应商为默认供应商
    setSpecCurSupplier(data) {
        return http.post(http.adornUrl('/hy-admin/backend/setSpecCurSupplier'), data)
    },
    //新增供应商
    addSpecSuppliers(data) {
        return http.post(http.adornUrl('/hy-admin/backend/addSpecSuppliers'), data)
    },
    //设置供应商为当前
    setSpecSuppliers(data) {
        return http.post(http.adornUrl('/hy-admin/backend/setSpecSuppliers'), data)
    },
    //获取附加属性和定制属性
    getSpecExtraAndSideInfo(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getSpecExtraAndSideInfo'), data)
    },
    //获取模型支持的定制属性
    getGoodsModelSupportDesignSides(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getGoodsModelSupportDesignSides'), data)
    },
    //提交规格属性修改
    setGoodsSpecExtraOrSideInfo(data) {
        return http.post(http.adornUrl('/hy-admin/backend/setGoodsSpecExtraOrSideInfo'), data)
    },
    ///数传定制属性对应图片数据
    uploadDesignSideAttributeImage(data) {
        return http.post(http.adornUrl('/hy-admin/backend/uploadDesignSideAttributeImage'), data)
    },
    //获取规格当前供应商
    getSpecCurSupplier(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getSpecCurSupplier'), data)
    },
    //获取规格属性列表
    getSpecAttrList(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getSpecAttrs'), data)
    },
    //获取规格检阅表
    getSpecStatistics(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getSpecStatistics'), data)
    },
    //新增规格
    createOrEditGoodsSpec(data) {
        return http.post(http.adornUrl('/hy-admin/backend/createOrEditGoodsSpec'), data)
    },
    //删除规格
    delSpec(data) {
        return http.post(http.adornUrl('/hy-admin/backend/delSpec'), data)
    },
    //设置规格缺货
    postSpecStockout(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/setOutofStockBySpecAndSupplier'), data)
    },
    //设置订单项缺货
    postOrderStockout(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/setOutofStock'), data)
    },
    //获取规格信息
    getGoodsSpecSumInfo(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getGoodsSpecSumInfo'), data)
    },
    //重打图片
    againOrder(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/reProduceOrderItemWithProblem'), data)
    },
    //获取型号表
    getModelList(data) {
        return http.post(http.adornUrl('/hy-admin/backend/getStuffTypes'), data)
    },
    //订单退款
    refundOrder(data) {
        return http.post(http.adornUrl('/hy-admin/index/order/refundOrder'), data)
    },
    //订单项退款
    refundOrderItem(data) {
        return http.post(http.adornUrl('/hy-admin/index/order/refundOrderItem'), data)
    },
    //过滤订单
    getOrders(data) {
        return http.post(http.adornUrl('/hy-admin/index/order/getOrders'), data)
    },
    //获取售后订单列表
    getRefundOrderItems(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getRefundOrderItems'), data)
    }
}
export default productApi
