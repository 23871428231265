export default {
    path: "/select",
    component: () => import("@/views/home/components/productFlow/index.vue"),
    name: "productflow",
    children: [
        {
            path: "/OrderList",
            component: () => import("@/views/home/components/productFlow/OrderList"),
            name: "OrderList",
            meta: { title: "订单列表" }
        },
        {
            path: "/filtration",
            component: () => import("@/views/home/components/productFlow/filtration/index.vue"),
            name: "filtration",
            meta: { title: "高级查询" }
        },
        {
            path: "/batch",
            component: () => import("@/views/home/components/productFlow/batch/index.vue"),
            name: "batch",
            meta: { title: "批次管理" },
            children: [
                {
                    path: "/allBatch",
                    component: () => import("@/views/home/components/productFlow/batch/allBatch/index.vue"),
                    name: "allBatch",
                    meta: { title: "所有批次" }
                },
                {
                    path: "/crustBatch",
                    component: () => import("@/views/home/components/productFlow/batch/crustBatch/index.vue"),
                    name: "crustBatch",
                    meta: { title: "待打壳批次" }
                },
                {
                    path: "/dispenseBatch",
                    component: () => import("@/views/home/components/productFlow/batch/dispenseBatch/index.vue"),
                    name: "dispenseBatch",
                    meta: { title: "待点胶批次" }
                }, {
                    path: "/deliveryBatch",
                    component: () => import("@/views/home/components/productFlow/batch/deliveryBatch/index.vue"),
                    name: "deliveryBatch",
                    meta: { title: "待发货批次" }
                },
                {
                    path: "/myBatch",
                    component: () => import("@/views/home/components/productFlow/batch/myBatch/index.vue"),
                    name: "myBatch",
                    meta: { title: "我的批次" }
                },
                {
                    path: "/deliver",
                    component: () => import("@/views/home/components/productFlow/batch/Delivery"),
                    name: "Delivery",
                    meta: { title: "发货" }
                }
            ]
        },
        {
            path: "/locker",
            component: () => import("@/views/home/components/productFlow/locker/index.vue"),
            name: "locker",
            meta: { title: "储物柜管理" }
        },
        {
            path: "/problem",
            component: () => import("@/views/home/components/productFlow/problem/problemList/ProblemList.vue"),
            name: "problem",
            meta: { title: "问题件" }
        }, {

            path: "/problemType",
            component: () => import("@/views/home/components/productFlow/problem/problemType/ProblemType.vue"),
            name: "ProblemType",
            meta: { title: "问题件类型" }
        }, {

            path: "/aftermarket",
            component: () => import("@/views/home/components/productFlow/aftermarket/Aftermarket.vue"),
            name: "Aftermarket",
            meta: { title: "售后" }
        }
    ]
}