import api from '@/api/api'
export default {


    //获取对应参数的手机品牌列表
    async getBrandList(ctx, data) {
        return await api.matedataApi.allBrands(data)
    },
    //新增手机品牌
    async addBrand(ctx, data) {
        return await api.matedataApi.newBrand(data)
    },
    //编辑手机品牌
    async editBrand(ctx, data) {
        return await api.matedataApi.editBrand(data)
    },
    //删除手机品牌
    async delPhoneBrand(ctx, data) {
        return await api.matedataApi.delPhoneBrand(data)
    },
    //获取所有手机品牌列表
    async getAllBrandList(ctx, data = false) {
        // if (ctx.rootState.brandList.lenght > 0 && data === false) {
        //     return
        // }
        const res = await api.matedataApi.allBrands()
        const brandList = res.data
        ctx.commit('brandData', brandList)
        return brandList
    },
    //获取手机品牌下的手机型号
    async getBrandFindType(ctx, data) {
        return await api.matedataApi.brandTypes(data)
    },
    //新增手机型号
    async addPhoneType(ctx, data) {
        return await api.matedataApi.newType(data)
    },
    //编辑手机型号
    async editPhoneType(ctx, data) {
        return await api.matedataApi.editType(data)
    },
    //删除手机型号
    async delPhoneType(ctx, data) {
        return await api.matedataApi.delPhoneType(data)
    },
    //获取材质分类列表
    async getCategories(ctx) {
        const res = await api.matedataApi.allStuffCategories()
        const categoryList = res.categories
        ctx.commit('', categoryList)
        return categoryList
    },
    //新增材质分类
    async addStuffType(ctx, data) {
        return await api.matedataApi.newStuffCategory(data)
    },
    //删除材质分类
    async delPhoneStuffCategory(ctx, data) {
        return await api.matedataApi.delPhoneStuffCategory(data)
    },
    //获取所有的材质分类
    async getAllStuffType() {
        return await api.matedataApi.allStuffCategories()
    },
    //获取材质列表
    async getAllStuff(ctx, data = false) {
        if (ctx.rootState.stuffList.lenght > 0 && data === false) {
            return
        }
        const res = await api.matedataApi.allStuffs()
        const stuffList = res.stuffs
        ctx.commit('stuffData', stuffList)
        return stuffList
    },
    //新增材质
    async addStuff(ctx, data) {
        return await api.matedataApi.newPhoneStuff(data)
    },
    //编辑材质
    async editStuff(ctx, data) {
        return await api.matedataApi.editPhoneStuff(data)
    },
    //删除材质
    async delPhoneStuff(ctx, data) {
        return await api.matedataApi.delPhoneStuff(data)
    },
    //获取原材料分类
    async getMaterialType() {
        return await api.matedataApi.getMaterialCateories()
    },

    //新增原材料分类
    async addMaterialType(ctx, data) {
        return await api.matedataApi.newMaterialCategory(data)
    },
    //获取原材料列表
    async getMaterials(ctx, data) {
        const res = await api.matedataApi.getMaterials(data)
        const materialList = res.materials
        return materialList
    },
    //新增原材料
    async addMaterial(ctx, data) {
        return await api.matedataApi.newMaterial(data)
    },
    //编辑原材料
    async editMaterial(ctx, data) {
        return await api.matedataApi.editMaterial(data)
    },
    //删除原材料
    async delMaterial(ctx, data) {
        return await api.matedataApi.delMaterial(data)
    }
}