<template>
  <el-dialog
    :title="title"
    :visible.sync="visible"
    :width="width"
    :close-on-click-modal="false"
  >
    <slot name="dialog-content"></slot>
    <slot name="dialog-footer"></slot>
  </el-dialog>
</template>
<script>
export default {
  name: "kyDialog",
  props: {
    title: String,
    show: Boolean,
    hide: Boolean,
    width: {
      type: String,
      default: "30%",
    },
  },
  data() {
    return {
      visible: false,
    };
  },
  watch: {
    show() {
      this.visible = true;
    },
    hide() {
      this.visible = false;
    },
  },
  methods: {
    //隐藏弹窗触发事件
    handleClose() {
      this.$emit("close");
    },
  },
};
</script>
<style lang="less" scoped>
</style>