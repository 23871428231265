
import {
    Message
} from 'element-ui'
export default new class Tool {
    //提交提示
    comfirmToast(res) {
        return new Promise((resolve, reject) => {

            if (res.code === 0 || res.code === 200) {
                Message({
                    type: "success",
                    message: res.msg || res.message
                })
                resolve()
            } else {
                Message({
                    type: "error",
                    message: res.msg || res.message
                })
                reject()
            }
        })
    }
    //输入过滤返回列表
    inpFilter(val, list) {
        return list.filter(item => {
            return item.indexOf(val) > -1
        })
    }
    //防抖函数
    debounce(delay) {
        clearTimeout(this.timeout)
        return new Promise((resolve) => {
            this.timeout = setTimeout(function () {
                resolve()
            }, delay)
        })
    }
    //生成唯一id
    onlyId() {
        return Date.parse(new Date()) / 1000 + Math.floor(Math.random() * 10000).toString()
    }
    //file转换成base64
    fileToBase64(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result)
            };
        })
    }
}