import api from '@/api/api'
export default {
    //获取代理类型列表
    async getAgentTypeList() {
        return await api.agentApi.getAgentTypes()
    },
    //新增代理类型
    async addAgentType(ctx, data) {
        return await api.agentApi.createAgentType(data)
    },
    //编辑代理类型
    async editAgentType(ctx, data) {
        return await api.agentApi.editAgentType(data)
    },
    //获取代理等级列表
    async getAgentLevelList() {
        return await api.agentApi.getAgentLevels()
    },
    //新增代理等级
    async addAgentLevel(ctx, data) {
        return await api.agentApi.createAgentLevel(data)
    },
    //编辑代理等级
    async editAgentLevel(ctx, data) {
        return await api.agentApi.editAgentLevel(data)
    },
    //获得代理列表
    async getAgentList(ctx, data) {
        return await api.agentApi.getAgents(data)
    },
    //新增代理
    async addAgent(ctx, data) {
        return await api.agentApi.createAgent(data)
    },
    //编辑代理
    async editAgent(ctx, data) {
        return await api.agentApi.editAgent(data)
    },
    //代理充值
    async agentRecharge(ctx, data) {
        return await api.agentApi.agentRecharge(data)
    },
    //获取代理所属产品信息
    async getAgentFindProdInfo(ctx, data) {
        return await api.agentApi.getAgentGoodsesPrice(data)
    },
    //编辑代理产品价格
    async editAgentProdPrice(ctx, data) {
        return await api.agentApi.setAgentGoodsPrice(data)
    },
    //获取代理所属快递信息
    async getAgentFindExpressInfo(ctx, data) {
        return await api.agentApi.getAgentExpressesPrice(data)
    },
    //编辑代理快递价格
    async editAgentExpressPrice(ctx, data) {
        return await api.agentApi.setAgentExpressPrice(data)
    },
    //删除代理类型
    async delAgentType(ctx, data) {
        return await api.agentApi.delAgentType(data)
    },
    //删除代理等级
    async delAgentLevel(ctx, data) {
        return await api.agentApi.delAgentLevel(data)
    },
    //删除代理
    async delAgent(ctx, data) {
        return await api.agentApi.delAgent(data)
    },
    async agentStatistic(ctx, data) {
        return await api.agentApi.agentStatistic(data)
    },
    //获取公告列表
    async getNoticeList(ctx, data) {
        return await api.agentApi.getNoticeList(data)
    },
    //新增公告
    async addNotice(ctx, data) {
        return await api.agentApi.postAddNotice(data)
    },
    //获取公告详情
    async getNoticeDetail(ctx, data) {
        return await api.agentApi.getNoticeDetail(data)
    }
}