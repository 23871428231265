export default [
    {
        path: "/TotalStatist",
        component: () => import("@/views/home/components/finance/factory-statist/TotalStatist"),
        name: "TotalStatist",
        meta: { title: "工厂统计" }
    },
    {
        path: "/OrderStatist",
        component: () => import("@/views/home/components/finance/factory-statist/OrderStatist"),
        name: "OrderStatist",
        meta: { title: "订单统计" }
    },
    {
        path: "/FinanceTotal",
        component: () => import("@/views/home/components/finance/FinanceTotal"),
        name: "FinanceTotal",
        meta: { title: "财务总览" }
    },
    {
        path: "/FinanceRecord",
        component: () => import("@/views/home/components/finance/FinanceRecord"),
        name: "FinanceRecord",
        meta: { title: "代理记录" }
    },
    {
        path: "/TodayExpense",
        component: () => import("@/views/home/components/finance/TodayExpense"),
        name: "TodayExpense",
        meta: { title: "消费详情" }
    }
]
