import api from '@/api/api'
export default {
    //获取供应商
    async getAllSuppliers(ctx, data = false) {
        if (ctx.rootState.supplierList.lenght > 0 && data === false) {
            return
        }
        const res = await api.supplierApi.getAllSuppliers()
        const supplierList = res.suppliers
        ctx.commit('supplierData', supplierList)
        return supplierList
    },
    //新增供应商
    async newSupplier(ctx, data) {
        return await api.supplierApi.newSupplier(data)
    },
    //编辑供应商
    async editSupplier(ctx, data) {
        return await api.supplierApi.editSupplier(data)
    },
    //删除供应商
    async delSupplier(ctx, data) {
        return await api.supplierApi.delSupplier(data)
    },
    //获取符合筛选的所有库存
    async getInventoryList(ctx, data) {
        const res = await api.inventoryApi.getStocks(data)
        return res.stocks
    },
    //添加对应原材料库存
    async addMaterialInventory(ctx, data) {
        return await api.inventoryApi.addStockByMaterials(data)
    },
    //添加对应规格的原材料库存
    async addSpecInventory(ctx, data) {
        return await api.inventoryApi.addStockByGoods(data)
    },
    //获取所有的损耗类型
    async getAllLossType() {
        return await api.inventoryApi.wastageTypes()
    },
    //增加损耗类型
    async addLossType(ctx, data) {
        return await api.inventoryApi.addWastageType(data)
    },
    //编辑损耗类型
    async editLossType(ctx, data) {
        return await api.inventoryApi.editWastageType(data)
    },
    //删除损耗类型
    async deleteLossType(ctx, data) {
        return await api.inventoryApi.delWastageType(data)
    },
    //获取损耗列表
    async getLossList(ctx, data) {
        return await api.inventoryApi.getLossList(data)
    },
    //新增原材料损耗
    async addMaterialLoss(ctx, data) {
        return await api.inventoryApi.addWastageStockByMaterials(data)
    },
    //新增产品损耗
    async addProdLoss(ctx, data) {
        return await api.inventoryApi.addWastageStockByGoods(data)
    }
}