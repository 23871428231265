import api from '@/api/api'
export default {
    //获取快递商列表
    async getAllExpress(ctx, data) {
        return await api.expressApi.getAllExpress(data)
    },
    //新增快递
    async newExpress(ctx, data) {
        return await api.expressApi.newExpress(data)
    },
    //编辑快递
    async editExpress(ctx, data) {
        return await api.expressApi.editExpress(data)
    },
    //删除快递
    async delExpress(ctx, data) {
        return await api.expressApi.delExpress(data)
    }
}