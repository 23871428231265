import http from '@/util/http'
const flowApi = {
    //过滤订单项
    filterOrderItems(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/filterOrderItems'), data)
    },
    //获取存在过滤条件和对应过滤结果
    getFilters(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getFilters'), data)
    },
    //预先更新过滤条件名称所有过滤条件的过滤结果
    filterUpdate(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/filterUpdate'), data)
    },
    //创建过滤条件
    createFilter(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/createFilter'), data)
    },
    //快捷批量审核订单
    quickCheckOrderItems(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/quickCheckOrderItems'), data)
    },
    //更新满足某种过滤条件的某个订单数量
    stasticsFilterCount(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/stasticsFilterCount'), data)
    },
    //快捷打包压缩
    quickPackOrderItems(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/quickPackOrderItems'), data)
    },
    //获取所有批次
    getBatches(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getBatches'), data)
    },
    //手动调用生成批次文件
    genBatchPackFile(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/genBatchPackFile'), data)
    },
    //下载批次文件
    downloadBatchFile(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/downloadBatchFile'), data)
    },
    updateBatchExpressNo(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/updateBatchExpressNo'), data)
    },
    //获取待打壳批次
    getWaitingForDK(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getWaitingForDK'), data)
    },
    //获取待发货批次
    getWaitingForFH() {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getWaitingForFH'))
    },
    //获取待点胶批次
    getWaitingForDJ() {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getWaitingForDJ'))
    },
    //获取订单项图片
    getOrderItemImages(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getOrderItemImages'), data)
    },
    //获取批次订单
    getBatchItems(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getBatchItems'), data)
    },
    getAdminBatchs(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getAdminBatchs'), data)
    },
    completeBatches(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/completeBatches'), data)
    },
    //添加问题件
    addToItemWithProblem(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/addToItemWithProblem'), data)
    },
    //获取问题件
    getItemsWithProblem(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getItemsWithProblem'), data)
    },
    //获取问题件类型
    problemTypes() {
        return http.post(http.adornUrl('/hy-admin/backend/produce/problemTypes'))
    },
    //新增问题件类型
    newProblemType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/newProblemType'), data)
    },
    //编辑问题件类型
    editProblemType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/editProblemType'), data)
    },
    //删除问题件类型
    delProblemType(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/delProblemType'), data)
    },
    //获取某种角色的操作员列表
    getAdminsByRole(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/getAdminsByRole'), data)
    },
    //指派打壳操作员
    assignDkOperator(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/assignDkOperator'), data)
    },
    //指派点胶操作员
    assignDjOperator(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/assignDjOperator'), data)
    },
    //指派发货操作员
    assignFhOperator(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/assignFhOperator'), data)
    },
    //订单号和快递单号搜索订单项所有产品信息  
    searchOrderForFH(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/searchOrderForFH'), data)
    },
    //确定发货
    comfirmDeliver(data) {
        return http.post(http.adornUrl('/hy-admin/backend/produce/makeDelivery'), data)
    }
}
export default flowApi
