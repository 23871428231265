export default [
    {
        path: "/Supplier",
        component: () => import("@/views/home/components/inventory/Supplier"),
        name: "Supplier",
        meta: { title: "供应商管理" }
    },
    {
        path: "/storeManage",
        component: () => import("@/views/home/components/inventory/storeManage/index.vue"),
        name: "storeManage",
        meta: { title: "库存管理" }
    },
    {
        path: "/LossList",
        component: () => import("@/views/home/components/inventory/lose-manage/LossList"),
        name: "LossList",
        meta: { title: "损耗列表" }
    },
    {
        path: "/LossType",
        component: () => import("@/views/home/components/inventory/lose-manage/LossType"),
        name: "LossType",
        meta: { title: "损耗类型" }
    }
]