import Vue from 'vue'
import Vuex from 'vuex'
import commonState from './modules/common/state'
import commonActions from './modules/common/actions'
import commonMutations from './modules/common/mutations'
import metadataState from './modules/metadata/state'
import metadataActions from './modules/metadata/actions'
import metadataMutations from './modules/metadata/mutations'
import prodState from './modules/product/state'
import prodActions from './modules/product/actions'
import prodMutations from './modules/product/mutations'
import prodFlowState from './modules/prodFlow/state'
import prodFlowActions from './modules/prodFlow/actions'
import prodFlowMutations from './modules/prodFlow/mutations'
import inventoryState from './modules/inventory/state'
import inventoryActions from './modules/inventory/actions'
import inventoryMutations from './modules/inventory/mutations'
import financeState from './modules/finance/state'
import financeActions from './modules/finance/actions'
import financeMutations from './modules/finance/mutations'
import agentState from './modules/agent/state'
import agentActions from './modules/agent/actions'
import agentMutations from './modules/agent/mutations'
import expressState from './modules/express/state'
import expressActions from './modules/express/actions'
import expressMutations from './modules/express/mutations'

Vue.use(Vuex)

const state = {
  ...commonState,
  ...metadataState,
  ...prodState,
  ...prodFlowState,
  ...inventoryState,
  ...financeState,
  ...agentState,
  ...expressState
}
const actions = {
  ...commonActions,
  ...metadataActions,
  ...prodActions,
  ...prodFlowActions,
  ...inventoryActions,
  ...financeActions,
  ...agentActions,
  ...expressActions
}
const mutations = {
  ...commonMutations,
  ...metadataMutations,
  ...prodMutations,
  ...prodFlowMutations,
  ...inventoryMutations,
  ...financeMutations,
  ...agentMutations,
  ...expressMutations
}
export default new Vuex.Store({
  state,
  mutations,
  actions,
})
