<template>
  <viewer :images="images" style="z-index: 9999" v-if="src">
    <img class="image-item" :src="src" onerror="" />
  </viewer>
</template>
<script>
export default {
  name: "KyImage",
  props: {
    images: Array,
    src: String,
  },
  watch: {
    src(e) {
      console.log("1232333");
    },
  },
};
</script>
<style lang="less" scoped>
.image-item {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 100%;
  max-height: 100%;
}
</style>