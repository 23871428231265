import "babel-polyfill"
import Vue from 'vue'
import './assets/css/main.css'
import './assets/css/icon.css'
import './assets/css/theme-green/index.css'
import './assets/css/color-dark.css'
import './assets/css/animate.min.css'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI, {
  zIndex: 99
});



import App from './App.vue'
import router from './router'
import store from './store'

//方法
import tool from './util/tools'
Vue.prototype.$tool = tool

//全局注册自定义组件
import TableContainer from "@/components/TableContainer";
import kyDialog from "@/components/Dialog";
import Pagination from '@/components/Pagination'
import DeleteBtn from '@/components/DeleteBtn'
import KyImage from "@/components/KyImage";
Vue.component('TableContainer', TableContainer)
Vue.component('kyDialog', kyDialog)
Vue.component('Pagination', Pagination)
Vue.component('DeleteBtn', DeleteBtn)
Vue.component('KyImage', KyImage)

//挂载全局cookie
import VueCookies from 'vue-cookies'
Vue.use(VueCookies)

//全局使用qs模块
import qs from 'qs'
Vue.prototype.$qs = qs

//注册全局bus
Vue.prototype.$bus = new Vue()
Vue.prototype.base_url = 'http://newbackend.kylpdz.com'

//全局挂载接口文件
import api from '@/api/api'
Vue.prototype.$api = api

//全局使用预览插件
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
Vue.use(Viewer, {
  zIndex: 999999999
})
Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
