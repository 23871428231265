

export default {

    //获取品牌列表
    brandData(state, data) {
        state.brandList = data
    },
    //获取材质列表
    stuffData(state, data) {
        state.stuffList = data
    },
}