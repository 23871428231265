export default [
    {
        path: "/ProdModel",
        component: () => import("@/views/home/components/produce/ProdModel"),
        name: "ProdModel",
        meta: { title: "产品模型" }
    },
    // {
    //     path: "/deploy",
    //     component: ()=>import("@/views/home/components/produce/deploy/index.vue"),
    //     name: "deploy",
    //     meta: { title: "规格配置" }
    //   },
    {
        path: "/ProdList",
        component: () => import("@/views/home/components/produce/ProdList"),
        name: "ProdList",
        meta: { title: "产品列表" }
    },
    {
        path: "/SpecList",
        component: () => import("@/views/home/components/produce/spec/SpecList"),
        name: "SpecList",
        meta: { title: "规格列表" }
    },
    {
        path: "/SpecInspect",
        component: () => import("@/views/home/components/produce/spec/SpecInspect"),
        name: "SpecInspect",
        meta: { title: "规格检阅" }
    },
    {
        path: "/PhoneModel",
        component: () => import("@/views/home/components/produce/PhoneModel"),
        name: "PhoneModel",
        meta: { title: "手机壳型号表" }
    },
]