import http from '@/util/http'
const financeApi = {
    //代理充值
    agentRecharge(data) {
        return http.post(http.adornUrl('/hy-admin/backend/agent/agentRecharge'), data)
    },
    //订单统计
    getInOutOrderStatistic(data) {
        return http.post(http.adornUrl('/hy-admin/backend/balance/getInOutOrderStatistic'), data)
    },
    //获取整体充值和资金使用情况
    getTotalRechargesAndUsage(data) {
        return http.post(http.adornUrl('/hy-admin/backend/balance/getTotalRechargesAndUsage'), data)
    },
    //获取每个代理类型下代理的充值和使用情况
    getCategoryAgentsStatistic(data) {
        return http.post(http.adornUrl('/hy-admin/backend/balance/getCategoryAgentsStatistic'), data)
    },
    //获取代理的充值和使用情况
    getAgentBalanceLog(data) {
        return http.post(http.adornUrl('/hy-admin/backend/balance/getAgentBalanceLog'), data)
    },
    //获取代理订单信息
    getAgentOrders(data) {
        return http.post(http.adornUrl('/hy-admin/backend/balance/getAgentOrders'), data)
    }
}
export default financeApi