export default [
    {
        path: "/AgentManage",
        component: () => import("@/views/home/components/agent/AgentManage"),
        name: "AgentManage",
        meta: { title: "代理管理" }
    },
    {
        path: "/AgentType",
        component: () => import("@/views/home/components/agent/AgentType"),
        name: "AgentType",
        meta: { title: "代理类型" }
    },
    {
        path: "/AgentLevel",
        component: () => import("@/views/home/components/agent/AgentLevel"),
        name: "AgentLevel",
        meta: { title: "代理等级" }
    },
    {
        path: "/Notice",
        component: () => import("@/views/home/components/agent/notice/NoticeList"),
        name: "Notice",
        meta: { title: "通知列表" }
    },
    {
        path: "/NoticeDetail",
        component: () => import("@/views/home/components/agent/notice/NoticeDetail"),
        name: "NoticeDetail",
        meta: { title: "通知详情" }
    }
]