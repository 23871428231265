
import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '@/store'
import user from './module/user'
import matedata from './module/metadata'
import product from './module/product'
import work from './module/work'
import agency from './module/agency'
import express from './module/express'
import inventory from './module/inventory'
import finance from './module/finance'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/index'
  },
  {
    path: '/',
    name: 'Home',
    component: () => import('@/views/home/Home.vue'),
    meta: { title: '首页' },
    children: [
      {
        path: '/index',
        component: () => import('@/views/home/components/default/index.vue'),
        name: 'index',
        meta: { title: '首页' },
      },
      ...matedata,
      user,
      ...product,
      work,
      ...agency,
      express,
      ...inventory,
      ...finance
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/Login.vue'),
    name: 'login',
    meta: { title: '登录' },
  },
  {
    path: '/404',
    component: () => import('@/views/error/404.vue'),
    name: '404',
    meta: { title: '404' },
  },
  {
    path: '*',
    redirect: '/404',
  },
]

const router = new VueRouter({
  routes,
})
router.beforeEach((to, from, next) => {
  document.title = `${to.meta.title} | vue-manage-system`;
  if (Vue.$cookies.isKey('token')) {
    store.commit('clearToken')
    next()
  } else {
    if (to.name === 'login') {
      next()
    } else {

      next({ name: 'login' })
    }
  }
})
export default router
