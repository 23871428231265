export default {
    path: "/sysUser",
    component: () => import("@/views/home/components/sysUser/index.vue"),
    name: "sysUser",
    children: [
        {
            path: "/Role",
            component: () => import("@/views/home/components/sysUser/Role/index.vue"),
            name: "Role",
            meta: { title: "角色管理" }
        },
        {
            path: "/User",
            component: () => import("@/views/home/components/sysUser/User/index.vue"),
            name: "User",
            meta: { title: "账号管理" }
        }
    ]
}