import api from '@/api/api'
export default {
    //过滤订单项
    async filterOrderItems(ctx, data) {
        return await api.flowApi.filterOrderItems(data)
    },
    //获取所有批次
    async getAllBatch(ctx, data) {
        return await api.flowApi.getBatches(data)
    },
    //根据订单号或者快递单号获取订单信息
    async getOrderNumOrExpressNumFindOrderList(ctx, data) {
        return await api.flowApi.searchOrderForFH(data)
    },
    async getProblemTypes(ctx) {
        return await api.flowApi.problemTypes()

    },
    //添加图片问题件
    async addImageIssue(ctx, data) {
        return await api.flowApi.addToItemWithProblem(data)
    },
    //确认发货
    async comfirmDeliver(ctx, data) {
        return await api.flowApi.comfirmDeliver(data)
    },
    //获取问题件类型
    async getProblemTypes(ctx, data) {
        return await api.flowApi.problemTypes()
    },
    //新增问题类型
    async addProblemType(ctx, data) {
        return await api.flowApi.newProblemType(data)
    },
    //编辑问题类型
    async editProblemType(ctx, data) {
        return await api.flowApi.editProblemType(data)
    },
    //删除问题类型
    async delProblemType(ctx, data) {
        return await api.flowApi.delProblemType(data)
    },
    //获取问题件列表
    async getProblemList(ctx, data) {
        return await api.flowApi.getItemsWithProblem(data)
    }
}