import http from '@/util/http'
const sysUserApi = {
    //获取角色列表
    getRoleList(data) {
        return http.post(http.adornUrl('/hy-admin/backend/userRole/getRoleList'), data)
    },
    // //新增角色
    // newRole(data){
    //     return http.post(http.adornUrl('/hy-admin/sys/role/save'),data)
    // },
    // //更新角色
    // updateRole(data){
    //     return http.post(http.adornUrl('/hy-admin/sys/role/update'),data)
    // },
    // //删除角色
    // deleteRole(data){
    //     return http.post(http.adornUrl('/hy-admin/sys/role/delete'),data)
    // },
    //获取用户列表
    getUserList(data) {
        return http.post(http.adornUrl('/hy-admin/backend/userRole/getUserList'), data)
    },
    //新增用户
    newUser(data) {
        return http.post(http.adornUrl('/hy-admin/backend/userRole/newUser'), data)
    },
    //更新用户
    updateUser(data) {
        return http.post(http.adornUrl('/hy-admin/backend/userRole/editUser'), data)
    },
    //删除用户
    deleteUser(data) {
        return http.post(http.adornUrl('/hy-admin/backend/userRole/delUser'), data)
    },
    //修改密码
    changePassword(data) {
        return http.post(http.adornUrl('/hy-admin/backend/userRole/changePwd'), data)
    },
    banUser(data) {
        return http.post(http.adornUrl('/hy-admin/backend/userRole/banUser'), data)
    }
}

export default sysUserApi
